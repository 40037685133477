import React, { createContext, useContext, useEffect, useState } from 'react';
import { IncommingDashboardMetrics } from 'src/Models/EmployeeModels';
import { IncommingEmployeeStatusModel } from 'src/Models/EmployeeStatusModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { EmployeeStatusService } from 'src/services/EmployeeStatusService';
import { IncommingSquadGroupGraphData, SquadGroupService } from 'src/services/SquadGroupService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';

interface ClientManagementFilterContextProps {
  metrics: IncommingDashboardMetrics | null;
  selectedFilter: string | null;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  squadGroupGraphData: IncommingSquadGroupGraphData[];
  updateSquadData: () => void;
  statusGraphData: IncommingEmployeeStatusModel[];
  updateStatusData: () => void;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startDate: Date | null;
  endDate: Date | null;
  clearAll: boolean;
  setClearAll: React.Dispatch<React.SetStateAction<boolean>>;
  setSquadGraphLoading: React.Dispatch<React.SetStateAction<boolean>>;
  squadGraphLoading: boolean;
  setStatusGraphLoading: React.Dispatch<React.SetStateAction<boolean>>;
  statusGraphLoading: boolean;
}

const ClientManagementFilterContext = createContext<ClientManagementFilterContextProps>({
  metrics: {
    countIncomming: 0,
    countInterview: 0,
    countConfirmed: 0,
    countNotConfirmed: 0,
    countLeftTFO: 0,
  },
  selectedFilter: '',
  setSelectedFilter: () => {},
  squadGroupGraphData: [],
  updateSquadData: () => {},
  statusGraphData: [],
  updateStatusData: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  startDate: null,
  endDate: null,
  clearAll: false,
  setClearAll: () => {},
  setSquadGraphLoading: () => {},
  squadGraphLoading: false,
  setStatusGraphLoading: () => {},
  statusGraphLoading: false,
});

export const ClientManagementFilterContextProvider: React.FC = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const { squad } = useContext(SquadsContext);
  const [squadGroupGraphData, setSquadGroupGraphData] = useState<IncommingSquadGroupGraphData[]>([]);
  const [statusGraphData, setStatusGraphData] = useState<IncommingEmployeeStatusModel[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [squadGraphLoading, setSquadGraphLoading] = useState(false);
  const [statusGraphLoading, setStatusGraphLoading] = useState(false);

  const squadGroupService = new SquadGroupService();
  const employeeStatusService = new EmployeeStatusService();

  const employeeService = new EmployeeService();

  const updateSquadData = async () => {
    setSquadGraphLoading(true);
    const response = await squadGroupService.GetIncommingSquadGroupGraphData(squad.squadId);
    const data = response;
    setSquadGroupGraphData(data);
    setSquadGraphLoading(false);
  };

  const updateStatusData = async () => {
    setStatusGraphLoading(true);
    const response = await employeeStatusService.getStatusBySquadId(squad.squadId, startDate, endDate);
    const data = response;
    setStatusGraphData(data);
    setStatusGraphLoading(false);
  };

  useEffect(() => {
    if (startDate && endDate) updateStatusData();
    if (!startDate && !endDate) updateStatusData();
  }, [startDate, endDate]);

  const getDashboardMetrics = async (squadId: string) => {
    const response = await employeeService.GetIncommingEmployeeMetrics(squadId);
    return response;
  };

  const { data: metrics } = useSWR(['incommingMetrics'], () => getDashboardMetrics(squad.squadId));

  return (
    <ClientManagementFilterContext.Provider
      value={{
        setStatusGraphLoading,
        statusGraphLoading,
        setSquadGraphLoading,
        squadGraphLoading,
        selectedFilter,
        setSelectedFilter,
        metrics: metrics ?? null,
        squadGroupGraphData,
        updateSquadData,
        statusGraphData,
        updateStatusData,
        setStartDate,
        setEndDate,
        startDate,
        endDate,
        clearAll,
        setClearAll,
      }}>
      {children}
    </ClientManagementFilterContext.Provider>
  );
};

export const useClientManagementFilterContext = () => {
  return useContext(ClientManagementFilterContext);
};
