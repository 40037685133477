import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { IncommingEmployeeStatusModel } from 'src/Models/EmployeeStatusModels';

import { BaseService } from './BaseService';

export type StatusHistoryDTO = {
  employeeId: number;
  employeeStatus: string;
  dateRecorded: Date;
};

export class EmployeeStatusService extends BaseService {
  constructor() {
    super('EmployeeStatus');
  }

  async getStatusBySquadId(squadId: string, startDate: Date | null, endDate: Date | null): Promise<IncommingEmployeeStatusModel[]> {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', new Date(startDate).toISOString());
    }

    if (endDate) {
      params.append('endDate', new Date(endDate).toISOString());
    }
    const queryString = params.toString();

    const url = queryString ? `/status/incomming/${squadId}?${queryString}` : `/status/incomming/${squadId}`;

    return (await this.httpService.get(url)).data;
  }

  async getIncommingEmployeesByStatus(
    squadId: string,
    statusName: string,
    startDate: Date | null,
    endDate: Date | null,
  ): Promise<DashboardEmployee[]> {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', new Date(startDate).toISOString());
    }

    if (endDate) {
      params.append('endDate', new Date(endDate).toISOString());
    }
    const queryString = params.toString();
    const url = queryString ? `/status/employees/${squadId}/${statusName}?${queryString}` : `/status/employees/${squadId}/${statusName}`;
    return (await this.httpService.get(url)).data;
  }

  async getEmmployeeStatusDelta(squadId: number, employeeId: number): Promise<StatusHistoryDTO[]> {
    return (await this.httpService.get(`/status/delta/${squadId}/${employeeId}`)).data;
  }
}
