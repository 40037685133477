import { Box } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend, Tooltip, ChartOptions } from 'chart.js';
import React, { useContext, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { IncommingEmployeeStatusModel } from 'src/Models/EmployeeStatusModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { EmployeeStatusService } from 'src/services/EmployeeStatusService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';

import { useClientManagementDashboardContext } from '../context/ClientManagementDashboardContext';
import { useClientManagementFilterContext } from '../context/ClientManagementFilterContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

interface StatusOverviewBarChartProps {
  statusData: IncommingEmployeeStatusModel[];
}

const StatusOverviewChart: React.FC<StatusOverviewBarChartProps> = ({ statusData }: StatusOverviewBarChartProps) => {
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);
  const { setEmployees } = useClientManagementDashboardContext();
  const { startDate, endDate } = useClientManagementFilterContext();
  const { squad } = useContext(SquadsContext);
  const employeeService = new EmployeeService();

  const employeeStatusService = new EmployeeStatusService();

  const statusNames = statusData.map((x) => x.statusName);
  const employeeCount = statusData.map((x) => x.employeeCount);

  const chartData = {
    labels: statusNames,
    datasets: [
      {
        backgroundColor: statusNames.map((_, index) => (index === selectedBarIndex ? 'lightblue' : entelectBlue)),
        borderColor: entelectBlue,
        data: employeeCount,
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;

        if (selectedBarIndex === index) {
          setSelectedBarIndex(null);
          setEmployeesResult();
        } else {
          const label = chartData.labels![index];
          setSelectedBarIndex(index);
          filterEmployees(label);
        }
      }
    },
  };

  const setEmployeesResult = async () => {
    const employees = await employeeService.GetAllIncommingEmployees(+squad.squadId);
    if (startDate && endDate) {
      setEmployees(
        employees.filter((x: DashboardEmployee) => {
          const availableDate = new Date(x.availableDate);
          return availableDate >= new Date(startDate) && availableDate <= new Date(endDate);
        }),
      );
    }
  };

  const filterEmployees = async (label: string) => {
    const newEmployeeList = await employeeStatusService.getIncommingEmployeesByStatus(squad.squadId, label, startDate, endDate);
    setEmployees(newEmployeeList);

    // apply global date filter when dates are selected.
    if (startDate && endDate)
      setEmployees(
        newEmployeeList.filter((x) => {
          const availableDate = new Date(x.availableDate);
          return availableDate >= new Date(startDate) && availableDate <= new Date(endDate);
        }),
      );
  };

  return (
    <Box id="certification-overview-chart">
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default StatusOverviewChart;
