import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PauseIcon from '@mui/icons-material/Pause';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import StopIcon from '@mui/icons-material/Stop';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  List,
  ListItem,
  Typography,
  Stepper,
  Step,
  StepIcon,
  DialogActions,
  Button,
  SvgIconTypeMap,
  Chip,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { EmployeeLearningPathDTO, LearningPathDTO } from 'src/Models/LearninpathModels';
import { TagDTO } from 'src/Models/TagModels';
import { useCategoryContext } from 'src/components/feedbackComponent/context/CategoryContext';
import FeedbackCard from 'src/components/feedbackComponent/feedback/history/FeedbackCard';
import { FeedbackService } from 'src/services/FeedbackService';
import { Feedback } from 'src/services/FeedbackType';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import { calculateTimeDifferenceInDaysOrWeeksWithText } from 'src/utilities/DateUtilities';

import LearningPathOverviewChecklist from '../LearningPathOverviewChecklist';

interface LearningPathOverviewModalProps {
  employeeLearningPath: EmployeeLearningPathDTO;
  onClose?: () => void;
}

interface StatusIcons {
  phaseName: string;
  iconName: JSX.Element;
  statusDateValue: string | ' ';
}

const LearningPathOverviewModal = ({ employeeLearningPath, onClose }: LearningPathOverviewModalProps) => {
  const configService = new LearningPathFallBackService();

  const feedbackService = new FeedbackService();
  const [feedback, setFeedbackData] = useState<Feedback[]>();
  const { categoryList } = useCategoryContext();
  const [pathConfig, setPathConfig] = useState<LearningPathDTO[]>();
  const configPhases = ['Cadet', 'Trooper', 'Corporal', 'Sergeant'];
  const [phaseStepperObject, setPhaseStepperObject] = useState<StatusIcons[]>();
  const [timeOnPath, setTimeOnPath] = useState<string>();
  const [learningPathTags, setLearningPathTags] = useState<TagDTO[] | []>([]);
  const [pathStartDate, setPathStartDate] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const getIconStyles = (IconComponent: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>, status: string): JSX.Element => {
    switch (status) {
      case 'Skipped':
        return <IconComponent sx={{ color: '#0028504D', top: '2px', left: '3px', pb: '10px' }} />;

      case 'InProgress':
        return <IconComponent sx={{ color: '#062951', top: '2px', left: '3px', pb: '10px' }} />;

      case 'Overdue':
        return <IconComponent sx={{ color: '#EF6C00', top: '2px', left: '3px', pb: '10px' }} />;

      case 'Paused':
        return <IconComponent sx={{ color: '#0028504D', top: '2px', left: '3px', pb: '10px' }} />;

      case 'Abandoned':
        return <IconComponent sx={{ color: '#D32F2F', top: '2px', left: '3px', pb: '10px' }} />;

      case 'Completed':
        return <IconComponent sx={{ color: '#062951', top: '2px', left: '3px', pb: '10px' }} />;

      default:
        return <IconComponent sx={{ color: '#00000061', top: '2px', left: '3px', pb: '10px' }} />;
    }
  };

  const getStatusDateValue = (phaseName: string): string | '' => {
    const phase = employeeLearningPath.phases?.find((phase) => phase.name === phaseName);
    if (phase === null || phase === undefined) {
      return ' ';
    } else {
      if (!phase.startDate) {
        return ' ';
      }
      if (phase.startDate && phase.endDate && phase.status === 'Completed') {
        const endDateTime = calculateTimeDifferenceInDaysOrWeeksWithText(phase.startDate, phase.endDate);
        return endDateTime.toString();
      }
      if (phase.status === 'InProgress') {
        const today = new Date().toISOString();
        const activeDate = calculateTimeDifferenceInDaysOrWeeksWithText(phase.startDate, today);
        return activeDate.toString();
      }
      return ' ';
    }
  };

  const getDaysOnPath = (pathStartDate: string) => {
    const currentDate: any = new Date();
    const startDate: any = new Date(pathStartDate);
    const timeDifference = currentDate - startDate;
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    setTimeOnPath(dayDifference.toString() + ' days');
  };

  const getPathStartDate = (dateString: string) => {
    const date = parseISO(dateString);
    return format(date, 'dd-MM-yy');
  };

  const fetchData = async () => {
    const data = await feedbackService.getEmployeeLearningPathFeedback(employeeLearningPath.id!);
    const phases = await configService.getLearningPathConfigs();
    setPathConfig(phases);
    setFeedbackData(data);
  };

  useEffect(() => {
    if (employeeLearningPath && employeeLearningPath.id && employeeLearningPath.startDate) {
      setPathStartDate(getPathStartDate(employeeLearningPath.startDate));
      setLearningPathTags(employeeLearningPath.tags ?? []);
      getDaysOnPath(employeeLearningPath.startDate);
      fetchData();
    }
  }, [employeeLearningPath]);

  useEffect(() => {
    setIsLoading(true);
    if (employeeLearningPath && employeeLearningPath.phases) {
      const stepperList: StatusIcons[] = [];
      const setIcons = () => {
        configPhases.forEach((phase) => {
          const employeePhaseStatus = employeeLearningPath.phases?.find((employeePhase) => employeePhase.name === phase)?.status ?? null;

          if (!employeePhaseStatus) {
            const stepperItem: StatusIcons = {
              phaseName: phase,
              iconName: getIconStyles(MoreHorizIcon, 'default'),
              statusDateValue: getStatusDateValue(phase),
            };
            stepperList.push(stepperItem);
          } else {
            switch (employeePhaseStatus) {
              case 'InProgress':
                const inProgressStepper: StatusIcons = {
                  iconName: getIconStyles(PersonPinCircleIcon, 'InProgress'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(inProgressStepper);
                break;
              case 'Overdue':
                const overdueStepper: StatusIcons = {
                  iconName: getIconStyles(NotificationImportantIcon, 'Overdue'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(overdueStepper);
                break;
              case 'Abandoned':
                const abandonedStepper: StatusIcons = {
                  iconName: getIconStyles(StopIcon, 'Abandoned'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(abandonedStepper);
                break;
              case 'Skipped':
                const skippedStepper: StatusIcons = {
                  iconName: getIconStyles(SkipNextIcon, 'Skipped'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(skippedStepper);
                break;
              case 'Paused':
                const pausedStepper: StatusIcons = {
                  iconName: getIconStyles(PauseIcon, 'Paused'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(pausedStepper);
                break;
              case 'Completed':
                const completedStepper: StatusIcons = {
                  iconName: getIconStyles(CheckCircleIcon, 'Completed'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(completedStepper);
                break;
              default:
                const stepperItem: StatusIcons = {
                  iconName: getIconStyles(MoreHorizIcon, 'default'),
                  phaseName: phase,
                  statusDateValue: getStatusDateValue(phase),
                };
                stepperList.push(stepperItem);
                break;
            }
          }
        });
      };
      setIcons();
      setPhaseStepperObject(stepperList);
      setIsLoading(false);
    }
  }, [employeeLearningPath, pathConfig]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open onClose={onClose} maxWidth={'md'}>
        <DialogTitle sx={{ display: 'flex', padding: '16px 24px', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'stretch' }}>
          Learning path overview
        </DialogTitle>
        {!isLoading ? (
          <DialogContent
            sx={{
              height: '700px',
              width: '800px',
              display: 'flex',
              padding: '0px 24px 20px 24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
            }}>
            {isUndefined(feedback) ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ gap: '19px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                <Box display={'flex'} flexDirection={'row'} gap={10}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <Typography sx={{ fontSize: '12px', lineHeight: '166%', color: '#646464' }}>Learning path</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '157%', letterSpacing: '0.1px', color: '#000000DE' }}>
                      {employeeLearningPath.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px', lineHeight: '166%', color: '#646464' }}>Status</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '157%', letterSpacing: '0.1px', color: '#000000DE' }}>
                      {employeeLearningPath.status}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Stepper>
                    {phaseStepperObject &&
                      phaseStepperObject.map((phase, index) => (
                        <Step key={index} sx={{ gap: '16px', width: '50px' }}>
                          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <StepIcon icon={phase.iconName} />
                            <Typography sx={{ fontSize: '14px', color: '#000000DE', fontWeight: '500' }}>{phase.phaseName}</Typography>
                            <Typography sx={{ fontSize: '12px', color: '#000000DE' }}>{phase.statusDateValue}</Typography>
                          </Box>
                        </Step>
                      ))}
                  </Stepper>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '28px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography sx={{ fontSize: '12px', lineHeight: '166%', color: '#646464' }}>Start date</Typography>
                    <Typography sx={{ color: '#000000DE', letterSpacing: '0.1px', fontSize: '14px', fontWeight: '500', lineHeight: '157%' }}>
                      {pathStartDate}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography sx={{ fontSize: '12px', lineHeight: '166%', color: '#646464' }}>Total time on path</Typography>
                    <Typography sx={{ color: '#000000DE', letterSpacing: '0.1px', fontSize: '14px', fontWeight: '500', lineHeight: '157%' }}>
                      {timeOnPath}
                    </Typography>
                  </Box>
                </Box>

                {learningPathTags.length > 0 && (
                  <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '60%', color: '#646464', letterSpacing: '0.4px' }}>
                      Associated tags
                    </Typography>
                    <List sx={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
                      {learningPathTags.map((tag: TagDTO) => (
                        <ListItem key={tag.tagId} sx={{ padding: 0, margin: '0 4px 0 0' }}>
                          <Chip
                            sx={{
                              border: '1px solid var(--secondary-main, #6DAA23)',
                              alignItems: 'center',
                              display: 'flex',
                              padding: '3px 4px',
                              borderRadius: '100px',
                            }}
                            size="small"
                            variant="outlined"
                            color="secondary"
                            label={tag.tagName!}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 'var(--1, 8px)', flex: '1 0 0', width: '100%' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: 'normal', color: '#000000' }}>Feedback summary</Typography>
                    <List sx={{ overflowY: 'scroll', scrollbarWidth: 'thin', maxHeight: '350px', width: '95%' }}>
                      {feedback.map((feedbackItem: Feedback) => (
                        <ListItem key={feedbackItem.feedbackId}>
                          <FeedbackCard showMoreIcon={false} feedbackItem={feedbackItem} categoryList={categoryList} displayEmployeeDetail={false} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <LearningPathOverviewChecklist selectedLearningPath={employeeLearningPath} />
                  </Box>
                </Box>
              </Box>
            )}
          </DialogContent>
        ) : (
          <CircularProgress />
        )}
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default LearningPathOverviewModal;
